import { withLazyComponent } from '@document/utils/lazy-component';
import { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { BaseIndex } from '../BaseIndex';

export const MapSearch = withLazyComponent(
  lazy(() => import('./MapSearch')),
  { height: '200px' }
);

export const renderMapSearch = (
  containerId = 'featured-storefronts',
  utmParams?: { [key: string]: string }
) => {
  const container = document.getElementById(containerId) as HTMLElement;
  const root = createRoot(container);

  root.render(
    <BaseIndex>
      <MapSearch utmParams={utmParams} />
    </BaseIndex>
  );
};
