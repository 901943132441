import { defineStyleConfig } from '@chakra-ui/react';

export const Tooltip = defineStyleConfig({
  baseStyle: {
    '--popper-arrow-bg': 'colors.tooltip.bg', // arrow color is finicky, github discussion here: https://github.com/chakra-ui/chakra-ui/discussions/6834
    border: '1px solid',
    px: 4,
    py: 3,
    bg: 'tooltip.bg',
    color: 'white',
    borderColor: 'tooltip.bg',
  },
  variants: {
    muted: {
      hasArrow: true,
      '--popper-arrow-bg': 'colors.slate.800',
      bg: 'slate.800',
      borderColor: 'whiteAlpha.200',
      color: 'white',
    },
  },
});
