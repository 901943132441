import {
  createMultiStyleConfigHelpers,
  defineStyle,
  defineStyleConfig,
} from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

/**
 * Form Label
 */
const formLabelBaseStyle = defineStyle((props) => {
  const color = mode('gray.500', 'whiteAlpha.500')(props);

  return {
    color: color,
    fontSize: 'xs',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
  };
});

const normal = defineStyle((props) => {
  const color = mode('gray.900', 'white')(props);

  return {
    color,
    fontSize: 'sm',
    fontWeight: 'medium',
    textTransform: 'normal',
    letterSpacing: '0px',
  };
});

export const FormLabel = defineStyleConfig({
  baseStyle: formLabelBaseStyle,
  variants: {
    normal,
  },
});

/**
 * Form
 */
const formHelpers = createMultiStyleConfigHelpers(['container']);

const sharedFormVariantStyles = {
  top: 0,
  left: 0,
  zIndex: 1,
  position: 'absolute',
  fontSize: 'md',
  letterSpacing: 'normal',
  pointerEvents: 'none',
  mx: 3,
  px: 1,
  my: 3,
  transformOrigin: 'left top',
  textTransform: 'none',
  fontWeight: 'normal',
  _disabled: {
    opacity: 1.0,
  },
};

// Floating Variant
const floating = formHelpers.definePartsStyle((props) => {
  const color = mode('gray.500', 'whiteAlpha.500')(props);

  return {
    container: {
      _focusWithin: {
        label: {
          transform: 'scale(0.85) translateY(-24px)',
          backgroundColor: 'bg.main',
        },
      },
      'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label':
        {
          transform: 'scale(0.85) translateY(-24px)',
          backgroundColor: 'bg.main',
        },
      // TODO: fix this styling to allow label to take placeholder position for input groups
      '.chakra-input__group:has(> input:not(:placeholder-shown))': {
        '+ label': {
          transform: 'scale(0.85) translateY(-24px)',
          backgroundColor: 'bg.main',
        },
      },
      label: {
        ...sharedFormVariantStyles,
        color,
      },
    },
  };
});

// Floating with Placeholder Variant
const floatingWithPlaceholder = formHelpers.definePartsStyle((props) => {
  const color = mode('gray.500', 'whiteAlpha.500')(props);

  return {
    container: {
      label: {
        ...sharedFormVariantStyles,
        color,
        transform: 'scale(0.85) translateY(-24px)',
        backgroundColor: 'bg.main',
      },
    },
  };
});

export const Form = formHelpers.defineMultiStyleConfig({
  variants: {
    floating,
    floatingWithPlaceholder,
  },
});
