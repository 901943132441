import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    backgroundColor: 'card.bg',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'card.border',
    borderRadius: '12px',
    boxShadow: '0px 0px 1px 0px var(--chakra-colors-card-boxShadow) inset;',
    backdropFilter: 'blur(2px)',
  },
  header: {
    textTransform: 'uppercase',
    fontSize: 'sm',
    fontWeight: '500',
    pb: 0,
    letterSpacing: '1px',
  },
  footer: {
    padding: '16px',
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: 'card.border',
  },
});

// variants
const secondaryGradient = definePartsStyle({
  container: {
    backgroundImage:
      'linear-gradient(82.56deg, #64BEFF 3.29%, rgba(100, 190, 255, 0.7) 97.27%)',
    backdropFilter: 'none',
    boxShadow: 'none',
    border: 'none',
  },
  header: {
    color: 'indigo.900',
    p: 4,
  },
  body: {
    p: 4,
    color: 'indigo.900',
    fontSize: 'sm',
  },
});

const unstyled = definePartsStyle({
  container: {
    backgroundColor: 'transparent',
    borderWidth: '0px',
    borderStyle: 'none',
    borderColor: 'none',
    borderRadius: '0px',
    boxShadow: 'none',
    backdropFilter: 'none',
  },
  header: {
    textTransform: 'uppercase',
    fontSize: 'sm',
    fontWeight: '500',
    pb: 0,
    px: 0,
    letterSpacing: '1px',
  },
  footer: {
    borderTopWidth: '0px',
    borderTopStyle: 'none',
    borderTopColor: 'none',
  },
});

export const Card = defineMultiStyleConfig({
  baseStyle,
  variants: { secondaryGradient, unstyled },
});
