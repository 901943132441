import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const outline = definePartsStyle({
  root: {
    border: '1px solid',
    borderColor: 'chakra-border-color',
    borderRadius: 'sm',
    overflow: 'hidden',
  },
  tab: {
    py: 1,
    fontWeight: 500,
    borderRadius: 'xs',
    color: 'whiteAlpha.400',
    position: 'relative',
    _after: {
      content: '""',
      position: 'absolute',
      borderRight: '1px solid',
      borderColor: 'chakra-border-color',
      right: 0,
      top: 2,
      bottom: 2,
    },
    _selected: {
      bg: 'white',
      color: 'indigo.900',
    },
    _last: {
      _after: {
        borderRight: 'none',
      },
    },
  },
  tabpanel: {
    borderTop: '1px solid',
    borderColor: 'chakra-border-color',
    _empty: {
      p: 0,
      borderTop: 'none',
    },
  },
});

const document = definePartsStyle({
  tablist: {
    borderBottom: 'none',
    gap: 7,
  },
  tab: {
    pb: 6,
    px: 0,
    color: 'tab.unselectedText',
    _selected: {
      color: 'tab.selectedText',
      borderBottom: '2px solid var(--chakra-colors-purple-500)',
    },
  },
});
const noBorder = definePartsStyle({
  root: {
    border: 'none',
  },
  tab: {
    borderRadius: 'sm',
    mr: 4,
    py: 2,
    px: '10px',
    fontSize: 'md',
    fontWeight: 500,
    color: 'whiteAlpha.600',
    _selected: {
      color: 'white',
      bg: 'whiteAlpha.100',
    },
    _focus: {
      boxShadow: 'none',
    },
    _hover: {
      bg: 'whiteAlpha.100',
    },
  },
  tabpanels: {
    mt: 2,
  },
  tablist: {
    overflowX: 'auto',
  },
});
const noBorderV2 = definePartsStyle({
  root: {
    border: 'none',
  },
  tab: {
    borderRadius: 'xs',
    m: 1,
    py: 1,
    px: 6,
    fontSize: 'md',
    fontWeight: 500,
    color: 'whiteAlpha.600',
    _selected: {
      color: 'white',
      bg: 'whiteAlpha.300',
    },
    _focus: {
      boxShadow: 'none',
    },
    _hover: {
      bg: 'whiteAlpha.100',
    },
  },
  tablist: {
    overflowX: 'auto',
    border: '1px solid',
    borderColor: 'whiteAlpha.100',
    borderRadius: 'xs',
  },
  tabpanel: {
    px: 0,
  },
});

export const Tabs = defineMultiStyleConfig({
  variants: { outline, noBorder, document, noBorderV2 },
});
