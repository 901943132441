import { inputAnatomy, switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/react';
import { inputSize } from '../sizes';

/**
 * Input
 */
const inputHelpers = createMultiStyleConfigHelpers(inputAnatomy.keys);

export const Input = inputHelpers.defineMultiStyleConfig({
  defaultProps: {
    colorScheme: 'primary',
    focusBorderColor: 'primary.500',
  } as { colorScheme: string; focusBorderColor: string },
  variants: {
    outline: inputHelpers.definePartsStyle({
      field: {
        background: 'input.bg',
        _placeholder: {
          color: 'input.placeholder',
        },
      },
    }),
  },
  sizes: {
    lg: {
      field: inputSize.lg,
      addon: inputSize.lg,
    },
    md: {
      field: inputSize.md,
      addon: inputSize.md,
    },
    sm: {
      field: inputSize.sm,
      addon: inputSize.sm,
    },
    xs: {
      field: inputSize.xs,
      addon: inputSize.xs,
    },
  },
});

/**
 * Switch
 */
const $width = cssVar('switch-track-width');
const $height = cssVar('switch-track-height');
const switchHelpers = createMultiStyleConfigHelpers(switchAnatomy.keys);

export const Switch = switchHelpers.defineMultiStyleConfig({
  defaultProps: {
    size: 'lg',
    colorScheme: 'primary',
  },
  sizes: {
    xs: {
      container: {
        [$width.variable]: '1.5rem',
        [$height.variable]: '0.75rem',
      },
    },
    sm: {
      container: {
        [$width.variable]: '1.75rem',
        [$height.variable]: '1rem',
      },
    },
    md: {
      container: {
        [$width.variable]: '2.25rem',
        [$height.variable]: '1.25rem',
      },
    },
    lg: {
      container: {
        [$width.variable]: '3.25rem',
        [$height.variable]: '1.75rem',
      },
    },
  },
  baseStyle: switchHelpers.definePartsStyle({
    thumb: {
      bg: 'switch.thumb',
    },
    track: {
      bg: 'switch.track',
      _checked: {
        bg: 'switch.checked',
      },
    },
  }),
  variants: {
    gradient: switchHelpers.definePartsStyle({
      thumb: {
        bg: 'switch.thumb',
      },
      track: {
        bg: 'switch.track',
        _checked: {
          bg: 'purpleGradient',
        },
      },
    }),
  },
});

/**
 * Radio
 */
const radioHelpers = createMultiStyleConfigHelpers(['control']);

export const Radio = radioHelpers.defineMultiStyleConfig({
  defaultProps: {
    colorScheme: 'primary',
  },
  baseStyle: {
    control: {
      borderRadius: 'full',
    },
  },
});

/**
 * Checkbox
 */
const checkboxHelpers = createMultiStyleConfigHelpers(['control']);

export const Checkbox = checkboxHelpers.defineMultiStyleConfig({
  defaultProps: {
    colorScheme: 'primary',
  },
  baseStyle: {
    control: {
      borderRadius: 'xs',
    },
  },
});
