import { ApolloProvider } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import { ChakraProvider, createStandaloneToast } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { FC, PropsWithChildren, StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async'; // Added HelmetProvider import
import { bloomTheme } from '~/common/theme/bloom.theme';
import { createApolloClient } from '~/services/document/utils/apollo-client';
import { EmbeddedConfig } from './embedded-config';

const { toast, ToastContainer } = createStandaloneToast({ theme: bloomTheme });
const apolloClientOptions = {
  uri: `${EmbeddedConfig.baseUrl}/graphql`,
  onError: (error: ErrorResponse) => {
    if (error.networkError) {
      toast({
        status: 'error',
        isClosable: true,
        duration: 10000,
        description: error.networkError?.name,
      });
    }
    error?.graphQLErrors?.map((error) => {
      toast({
        status: 'error',
        isClosable: true,
        duration: 10000,
        description: error.message,
      });
    });
  },
};
const apolloClient = createApolloClient({
  clientOptions: {
    ...apolloClientOptions,
  },
});

Sentry.init({
  dsn: 'https://0803e79f366dc5b12810a9767d447e05@o4506344812380160.ingest.us.sentry.io/4507170596585472',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.dedupeIntegration(),
  ],
  enabled: process.env.NODE_ENV === 'production',
  release: process.env.VERSION,
  environment: process.env.NODE_ENV,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
});

export const BaseIndex: FC<PropsWithChildren> = ({ children }) => (
  <StrictMode>
    <ToastContainer />
    <HelmetProvider>
      <ApolloProvider client={apolloClient}>
        <ChakraProvider theme={bloomTheme}>{children}</ChakraProvider>
      </ApolloProvider>
    </HelmetProvider>
  </StrictMode>
);
