import { defineStyleConfig } from '@chakra-ui/react';

export const Heading = defineStyleConfig({
  baseStyle: {
    fontWeight: 'medium',
  },
  variants: {
    subheading: {
      opacity: 0.5,
      fontWeight: 400,
    },
  },
  sizes: {
    display: {
      fontSize: ['5xl', '6xl', '6xl', '7xl'],
      lineHeight: [1.33, null, 1.2],
    },
    lg: {
      fontSize: ['4xl', '5xl', '5xl', '6xl'],
      lineHeight: [1.33, null, 1.2],
    },
    md: { fontSize: ['3xl', '4xl', '4xl', '5xl'], lineHeight: 1.5 },
    sm: {
      fontSize: ['2xl', '3xl', '3xl', '4xl'],
      lineHeight: 1.5,
      fontFamily: 'body',
    },
    xs: {
      fontSize: ['xl', '2xl', '2xl', '3xl'],
      lineHeight: 1.5,
      fontFamily: 'body',
    },
    xxs: {
      fontSize: ['lg', 'xl', 'xl', '2xl'],
      lineHeight: 1.5,
      fontFamily: 'body',
    },
    headline: {
      fontSize: 'lg',
      lineHeight: 1.5,
      fontFamily: 'body',
      fontWeight: 'normal',
    },
    smallUppercase: {
      textTransform: 'uppercase',
      fontSize: 'xs',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: '1px',
    },
  },
});
