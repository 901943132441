import { renderFeaturedStorefronts } from './featured-storefronts';
import { renderMapSearch } from './map-search';

globalThis.ENVS = {
  isCloud: process.env.NODE_ENV === 'production',
  environment: process.env.NODE_ENV,
  mapboxApiKey: process.env.MAPBOX_API_KEY,
} as typeof window.ENVS;

export const IndigoEmbedded = {
  featuredStorefronts: (containerId?: string) => {
    const utmParams = {
      utm_source: 'homepage',
      utm_medium: 'featured',
    };
    renderFeaturedStorefronts(containerId, utmParams);
  },

  mapSearch: (containerId?: string) => {
    const utmParams = {
      utm_source: 'homepage',
      utm_medium: 'featured',
    };
    renderMapSearch(containerId, utmParams);
  },
};

globalThis.IndigoEmbedded = IndigoEmbedded;
