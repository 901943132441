import './animation.css';
import './fonts.css';
import { colors } from './theme/colors';
import { Button } from './theme/components/button';
import { Card } from './theme/components/card';
import { Drawer } from './theme/components/drawer';
import { Form, FormLabel } from './theme/components/forms';
import { Heading } from './theme/components/heading';
import { Checkbox, Input, Radio, Switch } from './theme/components/inputs';
import { Modal } from './theme/components/modal';
import { Table } from './theme/components/table';
import { Tabs } from './theme/components/tabs';
import { Tag } from './theme/components/tag';
import { Tooltip } from './theme/components/tooltip';
export { colors } from './theme/colors';

export const theme = {
  semanticTokens: {
    colors: {
      'chakra-body-text': { _light: 'gray.900', _dark: 'whiteAlpha.900' },
      'chakra-body-bg': { _light: 'white', _dark: 'indigo.800' },
      'chakra-inverse-text': { _light: 'white', _dark: 'indigo.900' },
      'chakra-subtle-bg': { _light: 'gray.100', _dark: 'indigo.700' },
      'chakra-subtle-text': {
        _light: 'gray.600',
        _dark: 'whiteAlpha.400',
      },
      'chakra-placeholder-color': {
        _light: 'gray.500',
        _dark: 'whiteAlpha.400',
      },
      'chakra-border-color': {
        _light: 'gray.200',
        _dark: 'whiteAlpha.200',
      },
      // Backgrounds
      bg: {
        main: { _light: 'gray.50', _dark: 'indigo.800' },
        mainDark: { _light: 'gray.100', _dark: 'indigo.900' },
        mainDarkOpacity: {
          _light: 'whiteAlpha.800',
          _dark: `${String(colors.indigo[900])}F2`,
        },
        mainDarkAlpha: { _light: 'blackAlpha.100', _dark: 'whiteAlpha.200' },
        hover: { _light: 'gray.200', _dark: 'whiteAlpha.300' },
        activeSecondaryNav: { _light: 'gray.200', _dark: 'slate.800' },
        emphasis: { _light: 'gray.200', _dark: 'indigo.800' },
      },
      // Borders
      border: {
        box: {
          _light: 'gray.100',
          _dark: 'whiteAlpha.300',
        },
        divider: {
          _light: 'gray.200',
          _dark: 'whiteAlpha.200',
        },
      },
      // Cards
      card: {
        bg: { _light: 'gray.50', _dark: 'whiteAlpha.50' },
        bgSubtle: { _light: 'gray.100', _dark: 'whiteAlpha.200' },
        border: { _light: 'gray.200', _dark: 'whiteAlpha.100' },
        boxShadow: { _light: 'darkAlpha.400', _dark: 'whiteAlpha.400' },
      },
      // Links
      link: {
        nav: { _light: 'gray.400', _dark: 'whiteAlpha.500' },
        navHover: { _light: 'gray.500', _dark: 'whiteAlpha.700' },
        navActive: {
          _light: 'secondary.500',
          _dark: 'white',
        },
        secondaryNav: { _light: 'gray.400', _dark: 'white' },
        cyan: { _light: 'cyan.500', _dark: 'cyan.500' },
        skyBlue: { _light: 'cyan.500', _dark: 'cyan.500' },
        reverse: { _light: 'white', _dark: 'gray.800' },
      },
      // Tabs
      tab: {
        button: { _light: 'gray.400', _dark: 'white' },
        buttonHover: { _light: 'gray.500', _dark: 'whiteAlpha.700' },
        buttonSelected: { _light: 'secondary.500', _dark: 'white' },
        buttonSelectedBg: {
          _light: 'secondary.100',
          _dark: 'whiteAlpha.300',
        },
        selectedText: {
          _light: 'gray.800',
          _dark: 'white',
        },
        unselectedText: {
          _light: 'gray.400',
          _dark: 'whiteAlpha.500',
        },
      },
      pill: {
        bg: { _light: 'gray.200', _dark: 'neutral.800' },
      },
      icon: {
        light: { _light: 'gray.400', _dark: 'whiteAlpha.400' },
      },
      text: {
        light: { _light: 'gray.400', _dark: 'whiteAlpha.400' },
      },
      tooltip: {
        bg: { _light: 'purple.500', _dark: 'purple.500' },
      },
      input: {
        placeholder: { _light: 'gray.400', _dark: 'whiteAlpha.400' },
        bg: { _light: 'white', _dark: 'whiteAlpha.50' },
      },
      switch: {
        thumb: { _light: 'white', _dark: 'indigo.800' },
        track: { _light: 'gray.200', _dark: 'whiteAlpha.400' },
        checked: { _light: 'cyan.500', _dark: 'primary.500' },
      },
      dropzone: {
        border: { _light: 'gray.500', _dark: 'whiteAlpha.600' },
        borderPurple: { _light: 'purple.500', _dark: 'purple.500' },
      },
      validation: {
        error: { _light: 'red.500', _dark: 'red.500' },
        warning: { _light: 'yellow.600', _dark: 'yellow.500' },
        success: { _light: 'green.600', _dark: 'green.500' },
      },
    },
  },
  colors: {
    transparent: 'transparent',
    ...colors,
    primary: colors.cyan,
    secondary: colors.purple,
    primaryButton: colors.cyan,
    purpleGradient: `linear-gradient(236.01deg, ${String(
      colors.purple.gradient.start
    )} 7.7%, ${String(colors.purple.gradient.end)} 97.87%)`,
    blueGradient: `linear-gradient(236.01deg, ${String(
      colors.cyan.gradient.start
    )} 0%, ${String(colors.cyan.gradient.end)} 100%)`,
  },
  svgGradients: {
    // Used in SvgGradient component. Users pass in the gradient theme e.g. 'blue'
    blue: {
      id: 'blue-gradient',
      colors: [colors.cyan.gradient.end, colors.cyan.gradient.start],
      // TODO: Figure out how to use % instead of numbers for flexibility
      x1: '39',
      x2: '2.58375',
      y1: '11.1783',
      y2: '33.27',
    },
    purple: {
      id: 'purple-gradient',
      colors: [colors.purple.gradient.end, colors.purple.gradient.start],
      x1: '39',
      x2: '2.58375',
      y1: '11.1783',
      y2: '33.27',
    },
  },
  fonts: {
    body: 'Calibre, Matter, sans-serif',
    heading: 'Calibre, Matter, sans-serif',
    mono: 'Menlo, monospace',
    serif: 'Cardinal Photo, serif',
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.375rem',
    '2xl': '1.5rem',
    '3xl': '1.75rem',
    '4xl': '2rem',
    '5xl': '2.312rem',
    '6xl': '2.688rem',
    '7xl': '3.062rem',
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  lineHeights: {
    normal: '1.5',
  },
  letterSpacings: {
    eyebrow: '0.05em',
    display: '-0.25rem',
    normal: '0',
  },
  shadows: {
    sm: '0px 0px 10px 0px rgba(65,74,77,0.18)',
    md: '0px 2px 20px 2px rgba(65,74,77,0.18)',
    lg: '0px 4px 40px 8px rgba(65,74,77,0.18)',
    base: '0px 0px 10px 0px rgba(65,74,77,0.18)',
    interactive: {
      'default-01': '0px 2px 20px 2px rgba(65,74,77,0.18)',
      'hover-01': '0px 4px 40px 8px rgba(65,74,77,0.18)',
    },
  },
  sizes: {
    container: {
      storefront: '972px', // Max width of storefront container
    },
  },
  radii: {
    none: '0',
    xxs: '0.125rem',
    xs: '0.25rem',
    sm: '0.5rem',
    md: '0.75rem',
    lg: '1rem',
    full: '9999px',
  },
  breakpoints: {
    base: '0em',
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
    '3xl': '120em',
  },
  components: {
    Heading,
    Button,
    Card,
    Checkbox,
    Drawer,
    Radio,
    Switch,
    Input,
    FormLabel,
    Table,
    Tag,
    Tabs,
    Form,
    Tooltip,
    Modal,
  },
};
