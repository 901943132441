import { extendTheme } from '@chakra-ui/react';
import { colors, theme } from '../theme';

export const bloomTheme = extendTheme(theme, {
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  styles: {
    global: {
      body: {
        overflowX: 'clip',
      },
    },
  },
  semanticTokens: {
    colors: {
      'chakra-body-text': { _light: 'gray.800', _dark: 'whiteAlpha.900' },
      'chakra-body-bg': { _light: 'white', _dark: 'indigo.900' },
      'chakra-inverse-text': { _light: 'white', _dark: 'gray.800' },
      'chakra-subtle-bg': { _light: 'gray.100', _dark: 'gray.700' },
      'chakra-subtle-text': { _light: 'gray.600', _dark: 'gray.400' },
    },
  },
  colors: {
    primary: colors.cyan,
    primaryButton: colors.skyButton,
  },
});
