export const inputButtonHeights = {
  lg: { h: 14 },
  md: { h: 12 },
  sm: { h: 10 },
  xs: { h: 8 },
};

export const inputSize = {
  lg: {
    fontSize: 'lg',
    px: 5,
    borderRadius: 'sm',
    ...inputButtonHeights.lg,
  },
  md: {
    fontSize: 'md',
    px: 4,
    borderRadius: 'sm',
    ...inputButtonHeights.md,
  },
  sm: {
    fontSize: 'md',
    px: 4,
    borderRadius: 'sm',
    ...inputButtonHeights.sm,
  },
  xs: {
    fontSize: 'xs',
    px: 2,
    borderRadius: 'sm',
    ...inputButtonHeights.xs,
  },
};
