import { Center, CenterProps, Spinner } from '@chakra-ui/react';

import { SpinnerProps } from '@chakra-ui/react';
import React from 'react';

export const CenterSpinner: React.FC<
  SpinnerProps & { containerProps?: CenterProps }
> = ({ containerProps, ...spinnerProps }) => {
  return (
    <Center flexGrow={1} width="100%" {...containerProps}>
      <Spinner {...spinnerProps} />
    </Center>
  );
};
