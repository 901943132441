import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { theme } from '@chakra-ui/theme';
import { inputButtonHeights } from '../sizes';

const buttonTheme = theme.components.Button;

const cardHeader = defineStyle((props) => ({
  ...buttonTheme.variants?.solid({
    ...props,
  }),
  height: 6,
  letterSpacing: 0,
  py: 2,
  textTransform: 'none',
}));

const rounded = defineStyle((props) => ({
  ...buttonTheme.variants?.solid({
    ...props,
  }),
  borderRadius: 'full',
  height: 'auto',
  px: '14px',
  py: '8px',
}));

export const Button = defineStyleConfig({
  defaultProps: {
    colorScheme: 'primaryButton',
    size: 'md',
  },
  variants: { 'card-header': cardHeader, rounded },
  sizes: {
    lg: {
      ...inputButtonHeights.lg,
      px: 6,
      fontSize: 'lg',
      borderRadius: 'sm',
      fontWeight: '500',
    },
    md: {
      ...inputButtonHeights.md,
      px: 5,
      fontSize: 'md',
      borderRadius: 'sm',
      fontWeight: '500',
    },
    sm: {
      ...inputButtonHeights.sm,
      px: '10px',
      py: '6px',
      fontSize: 'sm',
      fontWeight: '500',
      borderRadius: 'sm',
    },
    xs: {
      ...inputButtonHeights.xs,
      px: 3,
      fontSize: 'xs',
      fontWeight: '500',
      borderRadius: 'xs',
    },
  },
});
