import { indigoColors } from './indigo.colors';

/**
 * NOTE: ALWAYS prefer using indigoColors instead of these!
 *
 * These defaultColors for original app that get overriden by indigoColors.
 * We're keeping them around for the internal app legacy which is not worth updating yet.
 */
export const defaultColors = {
  slate: {
    50: '#f8fafc',
    100: '#f1f5f9',
    200: '#e2e8f0',
    300: '#cbd5e1',
    400: '#94a3b8',
    500: '#64748b',
    600: '#475569',
    700: '#334155',
    800: '#151726',
    900: '#0f172a',
    950: '#020617',
  },
  gray: {
    50: '#f9fafb',
    100: '#f3f4f6',
    200: '#e5e7eb',
    300: '#d1d5db',
    400: '#9ca3af',
    500: '#6b7280',
    600: '#62605E',
    700: '#31302F',
    800: '#1f2937',
    900: '#111827',
    950: '#030712',
    text: '#31302F',
  },
  zinc: {
    50: '#fafafa',
    100: '#f4f4f5',
    200: '#e4e4e7',
    300: '#d4d4d8',
    400: '#a1a1aa',
    500: '#71717a',
    600: '#52525b',
    700: '#3f3f46',
    800: '#27272a',
    900: '#18181b',
    950: '#09090b',
  },
  neutral: {
    50: '#fafafa',
    100: '#f5f5f5',
    150: '#EDEDEF',
    200: '#e5e5e5',
    300: '#d4d4d4',
    400: '#a3a3a3',
    500: '#737373',
    600: '#525252',
    700: '#404040',
    800: '#262626',
    900: '#171717',
    950: '#0a0a0a',
  },
  stone: {
    50: '#fafaf9',
    100: '#f5f5f4',
    200: '#e7e5e4',
    300: '#d6d3d1',
    400: '#a8a29e',
    500: '#78716c',
    600: '#57534e',
    700: '#44403c',
    800: '#292524',
    900: '#1c1917',
    950: '#0c0a09',
  },
  red: {
    50: '#fef2f2',
    100: '#fee2e2',
    200: '#fecaca',
    300: '#fca5a5',
    400: '#F65C5C', // From ZS Figma
    500: '#ef4444',
    600: '#dc2626',
    700: '#b91c1c',
    800: '#991b1b',
    900: '#7f1d1d',
    950: '#450a0a',
  },
  orange: {
    50: '#fff7ed',
    100: '#ffedd5',
    200: '#fed7aa',
    300: '#fdba74',
    400: '#fb923c',
    500: '#F25410',
    600: '#ea580c',
    700: '#c2410c',
    800: '#9a3412',
    900: '#7c2d12',
    950: '#431407',
    button: '#F25410',
  },
  tan: {
    200: '#F6F1EA80',
    400: '#F6F1EA',
    bglight: '#F6F1EA80',
    bg: '#F6F1EA',
  },
  amber: {
    50: '#fffbeb',
    100: '#fef3c7',
    200: '#fde68a',
    300: '#fcd34d',
    400: '#fbbf24',
    500: '#f59e0b',
    600: '#d97706',
    700: '#b45309',
    800: '#92400e',
    900: '#78350f',
    950: '#451a03',
  },
  yellow: {
    50: '#fefce8',
    100: '#fef9c3',
    200: '#fef08a',
    300: '#fde047',
    400: '#facc15',
    500: '#eab308',
    600: '#ca8a04',
    700: '#a16207',
    800: '#854d0e',
    900: '#713f12',
    950: '#422006',
  },
  lime: {
    50: '#f7fee7',
    100: '#ecfccb',
    200: '#d9f99d',
    300: '#bef264',
    400: '#a3e635',
    500: '#84cc16',
    600: '#65a30d',
    700: '#4d7c0f',
    800: '#3f6212',
    900: '#365314',
    950: '#1a2e05',
  },
  green: {
    50: '#f0fdf4',
    100: '#dcfce7',
    200: '#bbf7d0',
    300: '#86efac',
    400: '#47C689', // From ZS Figma
    500: '#22c55e',
    600: '#16a34a',
    700: '#15803d',
    800: '#166534',
    900: '#14532d',
    950: '#052e16',
  },
  emerald: {
    50: '#ecfdf5',
    100: '#d1fae5',
    200: '#a7f3d0',
    300: '#6ee7b7',
    400: '#34d399',
    500: '#10b981',
    600: '#059669',
    700: '#047857',
    800: '#065f46',
    900: '#064e3b',
    950: '#022c22',
  },
  teal: {
    50: '#f0fdfa',
    100: '#ccfbf1',
    200: '#99f6e4',
    300: '#5eead4',
    400: '#2dd4bf',
    500: '#14b8a6',
    600: '#0d9488',
    700: '#0f766e',
    800: '#115e59',
    900: '#134e4a',
    950: '#042f2e',
  },
  cyan: {
    50: '#ecfeff',
    100: '#cffafe',
    200: '#a5f3fc',
    300: '#67e8f9',
    400: '#22d3ee',
    500: '#06b6d4',
    600: '#0891b2',
    700: '#0e7490',
    800: '#155e75',
    900: '#164e63',
    950: '#083344',
  },
  sky: {
    50: '#e0f6ff',
    100: '#b7dff9',
    200: '#8dc8f0',
    300: '#64BEFF',
    400: '#3a9de1',
    500: '#2283c8',
    600: '#16669c',
    700: '#0b4971',
    800: '#002c46',
    900: '#00101c',
  },
  blue: {
    50: '#eff6ff',
    100: '#dbeafe',
    200: '#bfdbfe',
    300: '#93c5fd',
    400: '#60a5fa',
    500: '#3b82f6',
    600: '#2563eb',
    700: '#1d4ed8',
    800: '#1e40af',
    900: '#1e3a8a',
    950: '#172554',
  },
  indigo: {
    50: '#EBEBF9',
    100: '#C7C7EF',
    200: '#A4A4E5',
    300: '#7E78E1', // From ZS Figma
    400: '#5C5CD1',
    500: '#3939C6',
    600: '#2D2D9F',
    700: '#222277',
    800: '#17174F',
    900: '#0C0C2A',
    950: '#090B1E',
  },
  violet: {
    50: '#f5f3ff',
    100: '#ede9fe',
    200: '#ddd6fe',
    300: '#c4b5fd',
    400: '#a78bfa',
    500: '#8b5cf6',
    600: '#7c3aed',
    700: '#6d28d9',
    800: '#5b21b6',
    900: '#4c1d95',
    950: '#2e1065',
  },
  purple: {
    50: '#faf5ff',
    100: '#f3e8ff',
    200: '#e9d5ff',
    300: '#d8b4fe',
    400: '#c084fc',
    500: '#a855f7',
    600: '#9333ea',
    700: '#7e22ce',
    800: '#6b21a8',
    900: '#581c87',
    950: '#3b0764',
  },
  fuchsia: {
    50: '#fdf4ff',
    100: '#fae8ff',
    200: '#f5d0fe',
    300: '#f0abfc',
    400: '#e879f9',
    500: '#d946ef',
    600: '#c026d3',
    700: '#a21caf',
    800: '#86198f',
    900: '#701a75',
    950: '#4a044e',
  },
  pink: {
    50: '#fdf2f8',
    100: '#fce7f3',
    200: '#fbcfe8',
    300: '#f9a8d4',
    400: '#f472b6',
    500: '#ec4899',
    600: '#db2777',
    700: '#be185d',
    800: '#9d174d',
    900: '#831843',
    950: '#500724',
  },
  rose: {
    50: '#fff1f2',
    100: '#ffe4e6',
    200: '#fecdd3',
    300: '#fda4af',
    400: '#fb7185',
    500: '#f43f5e',
    600: '#e11d48',
    700: '#be123c',
    800: '#9f1239',
    900: '#881337',
    950: '#4c0519',
  },
  // Button Color Scheme
  skyButton: {
    50: '#e0f6ff',
    100: '#b7dff9',
    200: '#64BEFF',
    300: '#3a9de1',
    400: '#2283c8',
    500: '#64BEFF',
    600: '#3a9de1',
    700: '#2283c8',
  },
  blackButton: {
    50: '#ffffff',
    100: '#000000',
    200: '#000000',
    300: '#333333',
    400: '#555555',
    500: '#000000',
    600: '#333333',
    700: '#555555',
  },
  whiteButton: {
    50: '#000000',
    100: '#ffffff',
    200: '#ffffff',
    300: '#b7dff9',
    400: '#555555',
    500: '#ffffff',
    600: '#b7dff9',
    700: '#555555',
  },
  whiteAlpha: {
    // Can customize other values as we start to use them in designs
    25: 'rgba(255, 255, 255, 0.02)',
    50: 'rgba(255, 255, 255, 0.03)',
    100: 'rgba(255, 255, 255, 0.05)',
    200: 'rgba(255, 255, 255, 0.10)',
    400: 'rgba(255, 255, 255, 0.25)',
    600: 'rgba(255, 255, 255, 0.5)',
    700: 'rgba(255, 255, 255, 0.6)',
    800: 'rgba(255, 255, 255, 0.7)',
  },
};

export const colors = {
  ...defaultColors,
  ...indigoColors,
  darkButton: {
    50: '#ffffff',
    100: defaultColors.indigo[900],
    200: defaultColors.indigo[900],
    300: defaultColors.indigo[800],
    400: defaultColors.indigo[700],
    500: defaultColors.indigo[900],
    600: defaultColors.indigo[800],
    700: defaultColors.indigo[700],
  },
};
