import { modalAnatomy } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  defineStyleConfig,
} from '@chakra-ui/react';

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle } = createMultiStyleConfigHelpers(modalAnatomy.keys);

const baseStyle = definePartsStyle({
  header: {
    fontSize: 'sm',
    fontWeight: '500',
    borderBottom: '1px solid',
    borderColor: 'chakra-border-color',
    pb: 4,
  },
  body: {
    pt: 4,
    px: 8,
    pb: 6,
  },
  dialog: {
    bg: 'bg.main',
  },
  closeButton: {
    size: 'sm',
  },
});

export const Modal = defineStyleConfig({ baseStyle });
