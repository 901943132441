import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { colors } from '../../theme';

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys);

const light = definePartsStyle({
  container: {
    backgroundColor: 'white',
    border: '1px solid',
    borderColor: 'transparent',
    color: 'black',
    fontSize: 'xs',
    _light: {
      borderColor: 'gray.200',
    },
  },
  label: {
    color: 'black',
  },
});

const validation = definePartsStyle((props) => {
  const bgColor = mode('gray.100', 'whiteAlpha.100')(props);
  const color = mode('gray.400', 'whiteAlpha.400')(props);

  return {
    container: {
      borderRadius: 'xs',
      bgColor,
      color,
    },
  };
});

const gradient = definePartsStyle({
  container: {
    backgroundPosition: 'center',
    backgroundSize: 'calc(100% + 20px) 100%',
    bgGradient: `linear(to-r, ${String(
      colors.purple.gradient.start
    )} 5%, ${String(colors.purple.gradient.end)} 95%)`,
    border: '1px solid',
    borderColor: 'rgba(255, 255, 255, 0.10)',
    borderRadius: '20px',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '1px',
    lineHeight: 'normal',
    textTransform: 'uppercase',
  },
});

const blueGradient = definePartsStyle({
  container: {
    padding: '3px 8px 4px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '6px',
    borderRadius: '50px',
    background: 'blueGradient',
    boxShadow: '0px 0px 14px 0px rgba(0, 0, 0, 0.45)',
    color: 'indigo.900',
    fontSize: 'xs',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
  },
});

const dark = definePartsStyle({
  container: {
    padding: '3px 8px 4px 8px',
    borderRadius: '50px',
    background: 'rgba(9, 11, 28, 0.95)',
    boxShadow: '0px 0px 14px 0px rgba(0, 0, 0, 0.45)',
    textTransform: 'none',
    color: '#FFF',
    fontSize: 'xs',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
  },
});

export const Tag = defineMultiStyleConfig({
  variants: { light, dark, validation, gradient, blueGradient },
});
