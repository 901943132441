import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle(() => {
  return {
    th: {
      fontFamily: 'body',
    },
  };
});

export const Table = defineStyleConfig({
  baseStyle,
});
